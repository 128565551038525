main {
    flex: 1;
}

main ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    list-style: none;
}

@media (max-width: 650px) {
    main ul {
        grid-template-columns: 1fr;
    }
}